html,
body,
#root {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: rgb(70, 70, 70);
}
.default-layout {
  .title {
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
    grid-column: 1 / -1; /* Span across all columns */
  }
  padding-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .grid-container {
    align-items: center;
    padding-left: 5vw; // Adjust the margin value to your desired spacing
    padding-right: 5vw;
    padding-bottom: 5vh;
  }
  .container {
    width: 100%;
    align-items: center;
    .image {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; // Adjusts the image to fit within the container without distorting its aspect ratio
    }
  }
  .arrow-left {
    top: 3vw;
    left: 3vw;
    transform: translateY(-50%);
    font-size: 2.5rem;
    color: white;
    cursor: pointer;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-content {
  position: relative;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-image {
  max-width: 100%;
  max-height: 100%;
}

.overlay-arrow-right {
  position: absolute;
  top: 50%;
  right: 10vw;
  transform: translateY(-50%);
  font-size: 2.5rem;
  color: white;
  cursor: pointer;
}

.overlay-arrow-left {
  position: absolute;
  top: 50%;
  left: 10vw;
  transform: translateY(-50%);
  font-size: 2.5rem;
  color: white;
  cursor: pointer;
}

.overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  cursor: pointer;
}
