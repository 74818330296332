html,
body,
#root {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: rgb(70, 70, 70);
}
.info {
  background-image: url("../bgimages/infobg.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: block grid;
  justify-content: center;
}
.container {
  display: flex;
  justify-content: center;
}
.arrow-left {
  position: absolute;
  top: 3vw;
  left: 3vw;
  transform: translateY(-50%);
  font-size: 2.5rem;
  color: white;
  cursor: pointer;
}
.div-insta {
  display: flex;
  margin-top: 37vh;
  height: 10vh;
  width: auto;
  justify-content: center;
  font-size: x-large;
  color: white;
  cursor: pointer;
}
.div-mail {
  display: flex;
  justify-content: center;
  font-size: x-large;
  color: white;
}
